/* eslint-disable react/destructuring-assignment,react/prop-types */

import React, { Component } from 'react';
import toast from '../../utils/toast';
import { withFirebase } from '../Firebase';
import { Box } from '../../style/basicStyle';
import { inheritFonts, inheritWidth } from '../../style/genericStyling';

const INITIAL_STATE = {
  displayName: '',
  error: null
};

class DisplayNameChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { displayName } = this.state;

    this.props.firebase
      .doDisplayNameUpdate(displayName)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({ error });
        toast(error.message);
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { displayName, error } = this.state;

    const isInvalid = displayName === '' || displayName.length < 5;

    return (
      <Box fontSize={3} width={[1, 300]} p={[0]}>
        <form css={[inheritFonts, inheritWidth]} onSubmit={this.onSubmit}>
          <Box css={[inheritFonts, inheritWidth]} my={2} width={[1]}>
            Change Display Name
          </Box>
          <Box py={[1]} css={[inheritFonts, inheritWidth]}>
            <input css={[inheritFonts, inheritWidth]} name="displayName" value={displayName} autoComplete="new-username" onChange={this.onChange} type="username" placeholder="New Display Name" />
          </Box>
          <Box py={[1]} css={[inheritFonts, inheritWidth]}>
            <button css={[inheritFonts, inheritWidth]} disabled={isInvalid} type="submit">
              Change
            </button>
          </Box>
          {error && <p css={[inheritFonts, inheritWidth]}>{error.message}</p>}
        </form>
      </Box>
    );
  }
}

export default withFirebase(DisplayNameChangeForm);
