/* eslint-disable react/destructuring-assignment,react/prop-types */

import React, { Component } from 'react';
import { Box } from '../../style/basicStyle';
import { withFirebase } from '../Firebase';
import { inheritFonts, inheritWidth } from '../../style/genericStyling';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return (
      <Box fontSize={3} width={[1, 300]} p={[0]}>
        <form css={[inheritFonts, inheritWidth]} onSubmit={this.onSubmit}>
          <Box css={[inheritFonts, inheritWidth]} my={2} width={[1]}>
            Change Password
          </Box>
          <Box py={[1]} css={[inheritFonts, inheritWidth]}>
            <input css={[inheritFonts, inheritWidth]} name="passwordOne" value={passwordOne} autoComplete="new-password" onChange={this.onChange} type="password" placeholder="New Password" />
          </Box>
          <Box py={[1]} css={[inheritFonts, inheritWidth]}>
            <input css={[inheritFonts, inheritWidth]} name="passwordTwo" value={passwordTwo} autoComplete="new-password" onChange={this.onChange} type="password" placeholder="Confirm New Password" />
          </Box>
          <Box py={[1]} css={[inheritFonts, inheritWidth]}>
            <button css={[inheritFonts, inheritWidth]} disabled={isInvalid} type="submit">
              Change
            </button>
          </Box>
          {error && <p css={[inheritFonts, inheritWidth]}>{error.message}</p>}
        </form>
      </Box>
    );
  }
}

export default withFirebase(PasswordChangeForm);
